import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import GeneralHeader from '../components/Header/GeneralHeader'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import Faq from '../components/shared/FAQ/Faq'
import PBHeader from '../components/Header/PBHeader'
import TextCenteredPB from '../components/TextCentered/TextCenteredPB'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import ImageAndTextColumns from '../components/ImageAndTextColumns/ImageAndTextColumns'
import GreenCardsIP from '../components/GreenCards/GreenCardsIP'
import img from '../images/industry-sector/team_industry.svg'
import IndustryGrid from '../components/IndustrySector/IndustryGrid'
import icon1 from '../images/tax-credit/aerospace.svg'
import ip from '../images/ip.svg'
import copy from '../images/copy.svg'
import icon2 from '../images/tax-credit/cctv.svg'
import icon3 from '../images/tax-credit/paint.svg'
import icon4 from '../images/tax-credit/construction.svg'
import icon5 from '../images/tax-credit/electronics.svg'
import icon6 from '../images/tax-credit/engineering.svg'
import icon7 from '../images/tax-credit/materials.svg'
import icon8 from '../images/tax-credit/projects.svg'
import icon9 from '../images/tax-credit/sustainablity.svg'
import icon10 from '../images/tax-credit/pharmaceutical.svg'
import icon11 from '../images/tax-credit/testing.svg'
import { qs } from './claims-process'
import TextCols from '../components/TextCols/TextCols'
import rda from '../images/about-us/the_rda_way.svg'
import TwoCardSection from '../components/TwoCards/TwoCards'
import FaqAlt from '../components/shared/FAQ/FaqAlt'

export const qs5: Array<IQuestions> = [
  {
    question: 'When should a company consider patent portfolio management as a service?',
    answer: (
      <>
        <ul>
          <li>
            <div>
              <strong>Managing Multiple Patents:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;If a company has several patents across different markets and needs 
                a structured approach to maintenance, enforcement and monetisation, 
                a portfolio approach is advisable.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Optimising IP Strategy:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;It can ensure patents support business goals, whether for 
                competitive advantage, licensing, or litigation protection.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Cost Control &amp; Efficiency:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;Patent Portfolio Management balances costs against potential 
                returns when managing patent renewals, filings, and international protection.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Mergers, Acquisitions, or Investment Rounds:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;The value of a company’s IP is worth assessing for due-diligence purposes.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Competitor and Market Monitoring:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;By tracking industry trends, a company can avoid infringement risks.
              </span>
            </div>
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'What are the benefits to patent portfolio management?',
    answer: (
      <>
        <ul>
          <li>
            <div>
              <strong>Maximises IP Value:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;Ensures patents are effectively used for protection, licensing, 
                or revenue generation.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Cost Efficiency:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;Avoids unnecessary filings and renewals while prioritising 
                high-value patents.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Risk Mitigation:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;Reduces the likelihood of IP disputes and infringement issues.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Strategic Decision-Making:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;Aligns IP strategy with business goals, market opportunities, 
                and competitor activity.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Enhances Innovation:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;Identifies gaps in the portfolio, guiding R&amp;D efforts toward 
                new patentable technologies.
              </span>
            </div>
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'What are the benefits to understanding and protecting the IP created by my business?',
    answer: (
      <>
        <p>
          Among the advantages that a company can take from effective IP protection are:
        </p>
        <ul>
          <li>
            <div>
              <strong>Competitive Advantage:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;IP protection shields unique innovations, branding, and creative 
                works from being copied by competitors. It establishes market 
                differentiation, making it harder for others to replicate products 
                or services.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Revenue Generation:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;It enables businesses to license, sell, or franchise their IP 
                for additional income streams. Patented IP can also qualify for tax 
                benefits, such as the UK Patent Box, reducing corporate tax on profits 
                from patented inventions.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Legal Protection &amp; Risk Mitigation:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;Patents prevent unauthorised use or infringement of valuable assets. 
                They strengthen legal standing in disputes and make enforcement easier 
                if competitors violate IP rights.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Enhanced Business Valuation:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;A company with protected IP can benefit from increased valuation 
                for investors and in mergers or acquisitions. It also demonstrates 
                innovation and long-term growth potential to stakeholders.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Cost Efficiency &amp; R&amp;D Optimisation:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;By understanding the prevailing IP landscape, businesses can avoid 
                wasting resources on duplicating existing inventions. Patent landscaping 
                can also guide R&amp;D efforts by identifying gaps in existing IP and 
                patent landscapes.
              </span>
            </div>
          </li>
          <li>
            <div>
              <strong>Stronger Brand &amp; Customer Trust:</strong>
              <span style={{ fontWeight: '300' }}>
                &nbsp;Protecting trademarks and branding prevents counterfeit products 
                from damaging a company’s reputation, enhancing credibility and trust 
                with customers and partners.
              </span>
            </div>
          </li>
        </ul>
      </>
    ),
  },
];




const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Patent Box',
    url: '/patent-box',
  },
]


const IndustrySector = () => {
  return (
    <Layout
      activeLink="/patent-box"
      title="Patent Box"
      description="R&D Patent Box"
    >
      <PBHeader />

        <GreenCardsIP />

        <ImageAndTextColumns
        tag="PATENT BOX"
        heading="Patent Portfolio Management"
        body={[
          "Patent Portfolio Management is a strategic service that helps businesses oversee, optimise, and maximise the value of their patents. It ensures that a company’s patents align with its business objectives, reduce risks, and generate financial returns.",
          
  ]}
        img={img}
        imgAlt="People in a meeting"
      />
      <Faq questions={qs5} />
      <CallBackBanner />
    </Layout>
  )
}

export default IndustrySector
