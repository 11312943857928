import React from 'react'
import styled from 'styled-components'
import Button from '../shared/Button/Button'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import img1 from '../../images/tax-credit/team_table.svg'
import bottom from '../../images/homepage/bottom.svg'
import lines from '../../images/homepage/header-lines.svg'
import border from '../../images/homepage/border.svg'
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb'

const dataContent = {
  heading: 'Patent Box',
  subheading:
    '',
}

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'R&D Patent Box',
    url: '/Patent Box',
  },
]

const TaxCreditHeader = () => {
  return (
    <Container>
      {dataContent && (
        <MainGrid>
          <Breadcrumb data={breadcrumbData} />
          <Inner>
            <TextWrapper>
              {dataContent.heading && (
                <MainHeading>{dataContent.heading}</MainHeading>
              )}

              {dataContent.subheading && (
                <SubHeading>{dataContent.subheading}</SubHeading>
              )}
            </TextWrapper>
            <ImgMask>
              <Img src={img1} alt="Team meeting at a table" />
            </ImgMask>
          </Inner>
        </MainGrid>
      )}

      <Bottom />
    </Container>
  )
}

export default TaxCreditHeader

const Container = styled.header`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 31px 0 0px;
  min-height: 260px;
  background: linear-gradient(to bottom, #e4efe1 1%, #eaefdd, #f2efd8);

  width: 100%;
  position: relative;
  @media screen and (min-width: 768px) {
    padding: 0;
    flex-direction: row;
  }
`

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`
const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 555px;
  position: relative;
  z-index: 8;
  text-align: center;
  margin-bottom: 22px;
  @media (min-width: 768px) {
    text-align: left;
    align-items: flex-start;
    margin-bottom: 0;
    width: 50%;
    padding: 10rem 0 15rem;
  }
`

const SubHeading = styled.h6`
  margin-top: 15px;
  font-weight: 300;

  & b {
    font-weight: 700;
  }
  @media (min-width: 768px) {
    width: 80%;
  }
`

const MainHeading = styled.h1``

const ImgMask = styled.div`
  width: 70vw;
  position: relative;
  bottom: -10px;
  z-index: 6;
  @media (min-width: 768px) {
    width: 50%;
    right: 0%;
    bottom: -50px;
    /* max-width: 53rem; */
  }
`

const Img = styled.img`
  width: 100%;
`

const Bottom = styled.div`
  height: 23px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #ede9ce;
  @media (min-width: 768px) {
  }
`
