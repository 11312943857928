import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import Button from '../shared/Button/Button'
import SectionHeading from '../shared/SectionHeading'
import VerticalPadding from '../styling/VerticalPadding'

const GreenCards = () => {
  return (
    <Container>
      <VerticalPadding>
        <MainGrid noPaddingMobile>
          <HeadingContainer>
            
            <SectionHeading center>
              Introducing Patent Box
            </SectionHeading>
          </HeadingContainer>
          <Grid>
            <CardGreen>
              <CardHeading white>
                <h3>What is Patent Box?</h3>
                <p>
                  Patent Box is a tax- incentive scheme introduced by the UK Government and overseen by HMRC. It allows companies to apply a lower Corporation Tax rate to profits derived from patented inventions and certain other intellectual property (IP) rights. It helps businesses maximise the financial benefits of their IP.
                </p>
              </CardHeading>
              <Section white>
                <h6>There are several criteria that need to be met to be eligible to make a Patent Box claim, they are:</h6>
                 <div>
                <ol style={{paddingLeft:'20px'}}>
                <li style={{fontWeight:'400', fontSize:'1.6rem'}}>
                  To be a UK registered company
                </li>
                <li style={{fontWeight:'400', fontSize:'1.6rem'}}>
                  To be paying Corporation Tax in the UK
                </li>
                <li style={{fontWeight:'400', fontSize:'1.6rem'}}>
                  To have a granted UK or European patent
                </li>
                <li style={{fontWeight:'400', fontSize:'1.6rem'}}>
                  To be making profit relating to said patent
                </li>
                <li style={{fontWeight:'400', fontSize:'1.6rem'}}>
                  To have been responsible for the R&D that resulted in the patented invention
                </li>
                </ol>
                </div>
              </Section>
              
            </CardGreen>
            <Card>
              <CardHeading>
                <h3>
                  We can help you with your Patent Box claim
                </h3>
                <p>It requires expertise to understand both the full scope of the Patent Box claim and the relevant IP assets held by the business. and how this all relates to the profits generated. That is why at RDA, we are not only tax experts, but IP experts too.</p>
                <p>Your Patent Box submission cannot be a simple matter of form-filling. Only by truly understanding the scope of the Patent Box legislation can you realise and communicate the actual value of your patent portfolio to HMRC. That is why we pride ourselves on the unique consultancy approach delivered by our IP and tax experts.</p>
              </CardHeading>             
              <Section>
                <h6>With RDA, in addition to the preparation and submission of your Patent Box claim, you will have access to:</h6>
                <div>
                <ul>
                <li style={{fontWeight:'400', fontSize:'1.6rem'}}>
                  a dedicated Client Manager on call
                </li>
                <li style={{fontWeight:'400', fontSize:'1.6rem'}}>
                  a dedicated IP Manager
                </li>
                <li style={{fontWeight:'400', fontSize:'1.6rem'}}>
                  a technical report written by us, outlining all you need to know about your patent portfolio and providing insight into where your patent(s) will sit within the IP landscape
                </li>
                <li style={{fontWeight:'400', fontSize:'1.6rem'}}>
                  Patent Box best-practice coaching and workshops
                </li>
                </ul>
                </div>
              </Section>
            </Card>
          </Grid>
        </MainGrid>
      </VerticalPadding>
    </Container>
  )
}

export default GreenCards

const Container = styled.section``

const HeadingContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: 918px;
  margin: 0 auto;
  text-align: center;
  @media (min-width: 768px) {
    padding: 0;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 30px;
  @media (min-width: 768px) {
    margin-top: 54px;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
  }
`
const Card = styled.div`
  background: ${theme.background.hpHeader};
  padding: 47px 20px 40px;

  @media (min-width: 768px) {
    border-radius: 4px;
  }
  @media (min-width: 1024px) {
    padding: 80px 40px;
  }
  @media (min-width: 1200px) {
    padding: 80px 58px;
  }
`
const CardGreen = styled(Card)`
  background: ${theme.brand.blue};
  color: white;
`
interface ICardHeading {
  white?: boolean
}
const CardHeading = styled.div<ICardHeading>`
  & h3 {
    color: ${props => props.white && 'white'};
    font-weight: 300;
    font-family: Roboto;
    @media (min-width: 768px) {
      letter-spacing: -1.2px;
    }

    @media (min-width: 1200px) {
      letter-spacing: -1.54px;
    }
  }

  & p {
    color: ${props => props.white && 'rgba(255,255,255,0.8)'};
  }
  padding-bottom: 32px;
  border-bottom: 1px solid
    ${props => (props.white ? theme.grey.white80 : theme.grey.keyline)};

  @media (min-width: 768px) {
    text-align: left;
    padding-bottom: 47px;
  }
`

const Section = styled.div<ICardHeading>`
  padding: 35px 0;
  border-bottom: 1px solid
    ${props => (props.white ? theme.grey.white80 : theme.grey.keyline)};

  & p {
    color: ${props =>
      props.white ? 'rgba(255,255,255,0.8)' : theme.text.greyLight};
  }
  &:last-of-type {
    border-bottom: none;
    padding: 35px 0 0;
  }

  & h6 {
    font-weight: 700;
    margin-bottom: 8px;
    color: ${props => (props.white ? theme.text.white : theme.text.main)};
  }

  & .p-large {
  }
`
